import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowUp,
  faMapMarkedAlt,
  faMapSigns,
  faSearch,
  faUser,
} from "@fortawesome/free-solid-svg-icons"
import { faMicroblog } from "@fortawesome/free-brands-svg-icons"

const BookFeatures = () => {
  const data = useStaticQuery(graphql`
    query HomePageBookFeatures {
      prismicHomepage {
        data {
          header_1 {
            text
          }
          header_2 {
            text
          }
          sub_header {
            text
          }
          buy_title_1 {
            text
          }
          buy_title_2 {
            text
          }
          buy_title_3 {
            text
          }
          buy_title_4 {
            text
          }
          buy_title_5 {
            text
          }
          buy_title_6 {
            text
          }
          buy_text_1 {
            text
          }
          buy_text_2 {
            text
          }
          buy_text_3 {
            text
          }
          buy_text_4 {
            text
          }
          buy_text_5 {
            text
          }
          buy_text_6 {
            text
          }
        }
      }
    }
  `)

  return (
    <div className="my-12 text-center text-gray-700">
      <div className="container relative w-10/12 max-w-6xl mx-auto lg:py-12 lg:px-0">
        <h2 className="font-serif text-4xl font-extrabold leading-10 tracking-tight text-left text-gray-900 sm:mb-10 sm:text-5xl sm:leading-none md:text-6xl sm:text-center">
          {data.prismicHomepage.data.header_1.text}{" "}
          <span className="inline-block text-indigo-500">
            {data.prismicHomepage.data.header_2.text}
          </span>
        </h2>
        <p className="mt-3 text-left text-gray-700 opacity-75 lg:text-xl sm:text-center">
          {data.prismicHomepage.data.sub_header.text}
        </p>
      </div>
      <div className="my-8">
        <div className="w-10/12 max-w-6xl mx-auto">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div className="p-16 transition-all duration-150 rounded-lg shadow-xl bg-gradient-to-b from-indigo-50 ease">
              <div className="relative inline-flex items-center justify-center w-16 h-16 overflow-hidden text-white rounded-full">
                <FontAwesomeIcon
                  className="relative text-5xl text-indigo-500"
                  icon={faMicroblog}
                />
              </div>
              <div className="mt-3 mb-6">
                <h5 className="pb-2 font-serif text-xl font-bold leading-6 text-gray-700">
                  {data.prismicHomepage.data.buy_title_1.text}
                </h5>
                <p className="mt-1 text-base leading-6 text-gray-700">
                  {data.prismicHomepage.data.buy_text_1.text}
                </p>
              </div>
            </div>
            <div className="p-16 mt-10 transition-all duration-150 rounded-lg shadow-xl bg-gradient-to-b from-indigo-50 lg:mt-0 ease">
              <div className="relative inline-flex items-center justify-center w-16 h-16 overflow-hidden text-white rounded-full">
                <FontAwesomeIcon
                  className="relative text-5xl text-indigo-500"
                  icon={faMapMarkedAlt}
                />
              </div>
              <div className="mt-3 mb-6">
                <h5 className="pb-2 font-serif text-xl font-bold leading-6 text-gray-700">
                  {data.prismicHomepage.data.buy_title_2.text}
                </h5>
                <p className="mt-1 text-base leading-6 text-gray-700">
                  {data.prismicHomepage.data.buy_text_2.text}
                </p>
              </div>
            </div>
            <div className="p-16 mt-10 transition-all duration-150 rounded-lg shadow-xl bg-gradient-to-b from-indigo-50 lg:mt-0 ease">
              <div className="relative inline-flex items-center justify-center w-16 h-16 overflow-hidden text-white rounded-full">
                <FontAwesomeIcon
                  className="relative text-5xl text-indigo-500"
                  icon={faMapSigns}
                />
              </div>
              <div className="mt-3 mb-6">
                <h5 className="pb-2 font-serif text-xl font-bold leading-6 text-gray-700">
                  {data.prismicHomepage.data.buy_title_3.text}
                </h5>
                <p className="mt-1 text-base leading-6 text-gray-700">
                  {data.prismicHomepage.data.buy_text_3.text}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-8">
        <div className="w-10/12 max-w-6xl mx-auto">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div className="p-16 transition-all duration-150 rounded-lg shadow-xl bg-gradient-to-b from-indigo-50 ease">
              <div className="relative inline-flex items-center justify-center w-16 h-16 overflow-hidden text-white rounded-full">
                <FontAwesomeIcon
                  className="relative text-5xl text-indigo-500"
                  icon={faArrowUp}
                />
              </div>
              <div className="mt-3 mb-6">
                <h5 className="pb-2 font-serif text-xl font-bold leading-6 text-gray-700">
                  {data.prismicHomepage.data.buy_title_4.text}
                </h5>
                <p className="mt-1 text-base leading-6 text-gray-700">
                  {data.prismicHomepage.data.buy_text_4.text}
                </p>
              </div>
            </div>
            <div className="p-16 mt-10 transition-all duration-150 rounded-lg shadow-xl bg-gradient-to-b from-indigo-50 lg:mt-0 ease">
              <div className="relative inline-flex items-center justify-center w-16 h-16 overflow-hidden text-white rounded-full">
                <FontAwesomeIcon
                  className="relative text-5xl text-indigo-500"
                  icon={faUser}
                />
              </div>
              <div className="mt-3 mb-6">
                <h5 className="pb-2 font-serif text-xl font-bold leading-6 text-gray-700">
                  Moderne (Unternehmens-) Führung
                </h5>
                <p className="mt-1 text-base leading-6 text-gray-700">
                  {data.prismicHomepage.data.buy_text_5.text}
                </p>
              </div>
            </div>
            <div className="p-16 mt-10 transition-all duration-150 rounded-lg shadow-xl bg-gradient-to-b from-indigo-50 lg:mt-0 ease">
              <div className="relative inline-flex items-center justify-center w-16 h-16 overflow-hidden text-white rounded-full">
                <FontAwesomeIcon
                  className="relative text-5xl text-indigo-500"
                  icon={faSearch}
                />
              </div>
              <div className="mt-3 mb-6">
                <h5 className="pb-2 font-serif text-xl font-bold leading-6 text-gray-700">
                  {data.prismicHomepage.data.buy_title_6.text}
                </h5>
                <p className="mt-1 text-base leading-6 text-gray-700">
                  {data.prismicHomepage.data.buy_text_6.text}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookFeatures
