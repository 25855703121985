import React, { FunctionComponent } from "react";

interface Props {
  title: string;
  src: string;
  height: string;
  width: string;
  display: boolean;
}

const LinkedInPost: FunctionComponent<Props> = ({
  title,
  src,
  height,
  width,
  display,
}) => {
  return (
    <iframe
      className={
        display
          ? "w-full mb-4 overflow-hidden"
          : "w-full mb-4 overflow-hidden hidden"
      }
      scrolling="no"
      src={src}
      height={height}
      width={width}
      frameBorder="0"
      title={title}
    />
  );
};

export default LinkedInPost;
