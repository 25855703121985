import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBolt, faLightbulb, faRobot } from "@fortawesome/free-solid-svg-icons"

const AboutAuthor = () => {
  const data = useStaticQuery(graphql`
    query AboutAuthor {
      prismicHomepage {
        data {
          title_2 {
            text
          }
          title2 {
            text
          }
          author_job_description {
            text
          }
          author_name {
            text
          }
          author_picture {
            alt
            url
          }
          author_quote {
            text
          }
          reason_1_description {
            text
          }
          reason_1_title {
            text
          }
          reason_2_description {
            text
          }
          reason_2_title {
            text
          }
          reason_3_description {
            text
          }
          reason_3_title {
            text
          }
        }
      }
    }
  `)

  return (
    <div className="w-10/12 max-w-6xl px-6 pb-8 mx-auto mt-12 md:px-12">
      <div className="items-center -mx-6 md:flex md:-mx-12">
        <div className="w-full md:w-1/2 md:mt-0">
          <div className="overflow-hidden bg-white rounded-lg shadow-xl">
            <img
              className="w-full"
              loading="lazy"
              src={data.prismicHomepage.data.author_picture.url}
              alt={data.prismicHomepage.data.author_picture.alt}
            />
            <div className="p-12">
              <blockquote className="text-lg italic text-gray-700">
                <div className="relative">
                  <p>{data.prismicHomepage.data.author_quote.text}</p>
                </div>
              </blockquote>
              <div className="mt-10">
                <div>
                  <div className="font-semibold tracking-wider text-gray-700 uppercase">
                    {data.prismicHomepage.data.author_name.text}
                  </div>
                  <div className="text-gray-700">
                    {data.prismicHomepage.data.author_job_description.text}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full my-8 md:w-1/2 md:px-12">
          <h2 className="font-serif text-4xl font-bold text-gray-900">
            <span>{data.prismicHomepage.data.title2.text}</span>
            <span className="text-indigo-500">
              {" "}
              {data.prismicHomepage.data.title_2.text}
            </span>
          </h2>
          <div className="flex mt-10">
            <div>
              <FontAwesomeIcon
                className="relative text-4xl text-gray-700"
                icon={faRobot}
              />
            </div>
            <div className="mt-2 ml-4">
              <div className="text-lg font-semibold">
                {data.prismicHomepage.data.reason_1_title.text}
              </div>
              <p className="mt-2 text-gray-700">
                {data.prismicHomepage.data.reason_1_description.text}
              </p>
            </div>
          </div>
          <div className="flex mt-10">
            <div>
              <FontAwesomeIcon
                className="relative ml-3 text-4xl text-gray-700"
                icon={faBolt}
              />
            </div>
            <div className="mt-2 ml-6">
              <div className="text-lg font-semibold">
                {data.prismicHomepage.data.reason_2_title.text}
              </div>
              <p className="mt-2 text-gray-700">
                {data.prismicHomepage.data.reason_2_description.text}
              </p>
            </div>
          </div>
          <div className="flex mt-10">
            <div>
              <FontAwesomeIcon
                className="relative ml-3 text-4xl text-gray-700"
                icon={faLightbulb}
              />
            </div>
            <div className="mt-2 ml-6">
              <div className="text-lg font-semibold">
                {data.prismicHomepage.data.reason_3_title.text}
              </div>
              <p className="mt-2 text-gray-700">
                {data.prismicHomepage.data.reason_3_description.text}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutAuthor
