import { useStaticQuery, graphql } from "gatsby"
import React, { FunctionComponent } from "react"

const Hero: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query HomePageHero {
      prismicHomepage {
        data {
          main_subtitle {
            text
          }
          main_title_1 {
            text
          }
          main_title_2 {
            text
          }
          book_cover_picture {
            alt
            url
          }
        }
      }
    }
  `)

  return (
    <>
      <div className="flex flex-col items-center w-10/12 max-w-6xl mx-auto lg:flex-row">
        <div className="container flex flex-col w-full pt-10 mb-0 text-2xl text-gray-700 lg:w-1/2 sm:px-0 md:px-10 lg:items-start lg:mb-0">
          <h1 className="font-serif text-5xl text-center text-black sm:text-6xl sm:font-black lg:text-left">
            <span className="text-gray-900">
              {data.prismicHomepage.data.main_title_1.text}
            </span>
            <span className="block text-indigo-500">
              {data.prismicHomepage.data.main_title_2.text}
            </span>
          </h1>
          <p className="mt-6 text-base text-center text-gray-700 lg:text-left lg:text-lg">
            {data.prismicHomepage.data.main_subtitle.text}
          </p>
        </div>
        <div className="p-5 lg:w-1/2">
          <img
            src={data.prismicHomepage.data.book_cover_picture.url}
            alt={data.prismicHomepage.data.book_cover_picture.alt}
          />
        </div>
      </div>
      <div className="flex justify-center mb-16">
        <a
          href="https://www.amazon.de/Die-Disruptions-DNA-Schl%C3%BCsselfaktoren-Erfolg-Digitalisierung/dp/3752688351"
          className="inline-flex items-center justify-center px-20 py-3 font-serif text-base font-bold text-white transition duration-150 ease-in-out bg-indigo-500 border border-transparent rounded-md Class Properties min-w text hover:text-indigo-600 hover:bg-indigo-200 focus:outline-none focus:shadow-outline focus:border-indigo-300"
        >
          Jetzt bestellen
        </a>
      </div>
    </>
  )
}

export default Hero
