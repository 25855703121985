import React, { useState } from "react";
import { FunctionComponent } from "react";
import LinkedInPost from "../LinkedInPost/LinkedInPost";

const linkedInPosts = [
  {
    title: "Daniel Unkelhäußer",
    src:
      "https://www.linkedin.com/embed/feed/update/urn:li:share:6770594714040201216",
    height: "1050",
    width: "360",
    display: true,
  },
  {
    title: "Tina Müller",
    src:
      "https://www.linkedin.com/embed/feed/update/urn:li:share:6767469980561760256",
    height: "717",
    width: "360",
    display: true,
  },
  {
    title: "David Lorenz",
    src:
      "https://www.linkedin.com/embed/feed/update/urn:li:share:6771093272782749696",
    height: "831",
    width: "360",
    display: false,
  },
  {
    title: "Beyza Erkul",
    src:
      "https://www.linkedin.com/embed/feed/update/urn:li:share:6767815584383143937",
    height: "740",
    width: "360",
    display: false,
  },
  {
    title: "Tobias Ragge",
    src:
      "https://www.linkedin.com/embed/feed/update/urn:li:share:6766722779136966656",
    height: "800",
    width: "360",
    display: false,
  },
  {
    title: "Dr. Rosa Kriesche-Küderli",
    src:
      "https://www.linkedin.com/embed/feed/update/urn:li:share:6764548330811875328",
    height: "1200",
    width: "360",
    display: false,
  },
  // {
  //   title: "OWNLY",
  //   src:
  //     "https://www.linkedin.com/embed/feed/update/urn:li:share:6765588230248161281",
  //   height: "1000",
  //   width: "360",
  //   display: false,
  // },
];

const SocialPosts: FunctionComponent = () => {
  const [posts, setPosts] = useState(linkedInPosts);

  const handleIncreaseClick = () => {
    const newPosts = [...posts];
    const index = newPosts.findIndex((post) => !post.display);
    if (index !== -1) {
      newPosts[index] = { ...newPosts[index], display: true };
      newPosts[index + 1] = { ...newPosts[index + 1], display: true };
      setPosts(newPosts);
    }
  };

  const handleDecreaseClick = () => {
    const newPosts = [...posts];
    const index = newPosts.reverse().findIndex((post) => post.display);
    newPosts[index] = { ...newPosts[index], display: false };
    newPosts[index + 1] = { ...newPosts[index + 1], display: false };
    setPosts(newPosts.reverse());
  };

  return (
    <div className="my-12 text-center text-gray-700">
      <div className="container flex-col relative w-10/12 max-w-6xl mx-auto lg:py-12 lg:px-0">
        <h2 className="font-serif text-4xl font-extrabold leading-10 tracking-tight text-left text-gray-900 mb-10 sm:mb-0 sm:text-5xl sm:leading-none md:text-6xl sm:text-center">
          Das sagen{" "}
          <span className="inline-block text-indigo-500">LeserInnen</span>
        </h2>
      </div>
      <div className="w-10/12 mt-2 mx-auto max-w-6xl md:column-2 overflow-hidden">
        {posts.map(({ title, src, height, width, display }) => (
          <LinkedInPost
            key={title}
            title={title}
            src={src}
            height={height}
            width={width}
            display={display}
          />
        ))}
      </div>
      <div className="flex flex-col w-10/12 sm:flex-row justify-center mt-2 mx-auto">
        {posts.filter((post) => post.display).length >= 4 && (
          <button
            onClick={handleDecreaseClick}
            className="inline-flex items-center m-2 justify-center sm:w-5/12 lg:w-1/5 px-10 py-3 font-serif text-base font-bold text-white transition duration-150 ease-in-out bg-indigo-500 border border-transparent rounded-md Class Properties min-w text hover:text-indigo-600 hover:bg-indigo-200 focus:outline-none focus:shadow-outline focus:border-indigo-300"
          >
            Weniger Stimmen
          </button>
        )}
        {posts.some((post) => !post.display) && (
          <button
            onClick={handleIncreaseClick}
            className="inline-flex items-center m-2 justify-center sm:w-5/12 lg:w-1/5 px-10 py-3 font-serif text-base font-bold text-white transition duration-150 ease-in-out bg-indigo-500 border border-transparent rounded-md Class Properties min-w text hover:text-indigo-600 hover:bg-indigo-200 focus:outline-none focus:shadow-outline focus:border-indigo-300"
          >
            Mehr Stimmen
          </button>
        )}
      </div>
    </div>
  );
};

export default SocialPosts;
