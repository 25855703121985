import React from "react";
import { FunctionComponent } from "react";
import AboutAuthor from "../components/AboutAuthor/AboutAuthor";
import BookFeatures from "../components/BookFeatures/BookFeatures";
import BookInformation from "../components/BookInformation/BookInformation";
import SEO from "../components/Helmet/SEO";
import Hero from "../components/Hero/Hero";
import PageLayout from "../components/PageLayout/PageLayout";
import SocialPosts from "../components/SocialPosts/SocialPosts";

const Index: FunctionComponent = () => {
  return (
    <>
      <SEO
        keywords={[`digitalisierung`, `führung`, `buch`]}
        title="Die Disruptions-DNA"
      />
      <PageLayout>
        <div className="bg-gradient-to-b from-indigo-100">
          <Hero />
          <BookInformation />
        </div>
        <BookFeatures />
        <div className="bg-gradient-to-t from-indigo-100">
          {/*<ActionCall />*/}
          <SocialPosts />
          <AboutAuthor />
        </div>
      </PageLayout>
    </>
  );
};

export default Index;
