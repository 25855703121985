import { Dialog } from "@material-ui/core"
import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import BookPictures from "../BookPictures/BookPictures"

const BookInformation = () => {
  const data = useStaticQuery(graphql`
    query HomePageBookInformation {
      prismicHomepage {
        data {
          information {
            html
          }
          title {
            text
          }
          headline {
            text
          }
        }
      }
    }
  `)

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <div className="flex flex-col items-center w-10/12 max-w-6xl mx-auto my-12 lg:flex-row">
        <BookPictures handleClickOpen={handleClickOpen} />

        <div className="relative w-full h-full mt-12 lg:w-1/2 lg:pl-12 lg:my-0">
          <p className="text-base tracking-wide text-indigo-500 uppercase xl:text-lg">
            {data.prismicHomepage.data.headline.text}
          </p>
          <h2 className="mt-5 font-serif text-4xl font-bold leading-tight text-gray-900">
            {data.prismicHomepage.data.title.text}
          </h2>
          <div
            className="mt-3 space-y-3 text-base text-gray-600 xl:text-lg"
            dangerouslySetInnerHTML={{
              __html: data.prismicHomepage.data.information.html,
            }}
          />
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <BookPictures handleClickOpen={handleClickOpen} isModal />
      </Dialog>
    </>
  )
}

export default BookInformation
