import React, { FunctionComponent } from "react"
import SwiperCore, { Pagination, Zoom, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { useStaticQuery, graphql } from "gatsby"
import "swiper/swiper-bundle.min.css"

type Props = {
  handleClickOpen?: () => void
  isModal?: boolean
}

SwiperCore.use([Pagination, Zoom, Autoplay])

const BookPictures: FunctionComponent<Props> = ({
  handleClickOpen,
  isModal,
}) => {
  const data = useStaticQuery(graphql`
    query HomePageBookInformationPictures {
      prismicHomepage {
        data {
          book_picture_1 {
            alt
            url
          }
          book_picture_2 {
            alt
            url
          }
          book_picture_3 {
            alt
            url
          }
          book_picture_4 {
            alt
            url
          }
          book_picture_5 {
            alt
            url
          }
          book_picture_6 {
            alt
            url
          }
          book_picture_7 {
            alt
            url
          }
        }
      }
    }
  `)

  return (
    <Swiper
      slidesPerView={1}
      pagination={{ clickable: true }}
      zoom
      className={`w-full rounded-lg shadow-xl hover:shadow-2xl ${
        isModal ? "lg:w-full" : "lg:w-1/2"
      }`}
      onClick={handleClickOpen}
    >
      <SwiperSlide>
        <img
          src={data.prismicHomepage.data.book_picture_1.url}
          alt={data.prismicHomepage.data.book_picture_1.alt}
          className="mx-auto overflow-hidden"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={data.prismicHomepage.data.book_picture_2.url}
          alt={data.prismicHomepage.data.book_picture_2.alt}
          className="mx-auto overflow-hidden"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={data.prismicHomepage.data.book_picture_3.url}
          alt={data.prismicHomepage.data.book_picture_3.alt}
          className="mx-auto overflow-hidden"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={data.prismicHomepage.data.book_picture_4.url}
          alt={data.prismicHomepage.data.book_picture_4.alt}
          className="mx-auto overflow-hidden"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={data.prismicHomepage.data.book_picture_5.url}
          alt={data.prismicHomepage.data.book_picture_5.alt}
          className="mx-auto overflow-hidden"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={data.prismicHomepage.data.book_picture_6.url}
          alt={data.prismicHomepage.data.book_picture_6.alt}
          className="mx-auto overflow-hidden"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={data.prismicHomepage.data.book_picture_7.url}
          alt={data.prismicHomepage.data.book_picture_7.alt}
          className="mx-auto overflow-hidden"
        />
      </SwiperSlide>
    </Swiper>
  )
}

export default BookPictures
